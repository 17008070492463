export const Home = {
  lastScrollTop: -1,

  initialize: function() {   
    if ($("#home").length) {

      var observer = new IntersectionObserver(function(entries, o) {
        var scrollDown = ($(window).scrollTop() > Home.lastScrollTop)
        $.each(entries, function(index, entrie) {
          var element = $(entrie.target);
          if (!scrollDown && entrie.intersectionRatio > 0.2 && entrie.boundingClientRect.y > 100 && !element.hasClass("withoutFadeOut")) {
            element.removeClass("show")
          } else if (entrie.isIntersecting) {
            element.addClass("show")
          }
        })
        Home.lastScrollTop = $(window).scrollTop()
      }, { rootMargin: "0px 0px 200px 0px", threshold: [0, 0.7]});

      $.each($(".animate"), function(i,e) {
        observer.observe( e );
      });
    }
  },

}
