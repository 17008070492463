export const CarReservations = {

  initialize: function() {   
    if ($("#car-reservations-new").length) {
      $("#car-reservation-form #reservation_car_id").val("")
      $("#car-reservation-form #reservation_since_at").val($("#car-reservation-form").data("sinceAt"))
      $("#car-reservation-form #reservation_until_at").val($("#car-reservation-form").data("untilAt"))
      CarReservations.setClassInCalendar();
      CarReservations.bindClickCar()
      CarReservations.bindClickDate()
      CarReservations.bindPublications(".publications")
      CarReservations.bindModal()
      window.bindPublications = CarReservations.bindPublications
      if ($("#car-reservation-form").data("carId")) {
        $(".cars .car[data-id='"+$("#car-reservation-form").data("carId")+"']").click()
      }
    }
  },
    
  bindModal: function() {
    $('#modal-from-url').bind('show.bs.modal', function(event){
      $.get(event.relatedTarget.dataset.url, function(data, status, response){
        if (status == 'success') {
          $(event.target).find(".modal-content").html(data)
        }
      })
    });

    $('#modal-from-url').bind('hide.bs.modal', function(event){
      $(event.target).find(".modal-content").html("")
    });
  },

  bindClickCar: function() {
    $(".cars .list .car").click(function(e) {
      if ($(this).hasClass("disabled") || $(e.target).hasClass('info')) {
        return
      } else if ($(this).hasClass("active")) {
        $(this).removeClass("active")
        $("#car-reservation-form input.model").val("")
        $("#car-reservation-form #reservation_car_id").val("")
      } else {
        $(".cars .list .car").removeClass("active")
        $("#car-reservation-form input.model").val($(this).find(".car-title").text())
        $("#car-reservation-form #reservation_car_id").val($(this).data("id"))
        $(this).addClass("active")
      }
      CarReservations.getCalendar()
    })
  },

  bindClickDate: function() {
    $("#calendar table.calendar td:not('.disabled'):not('.blockade'):not('.holidays')").click(function() {
      if ($(this).hasClass("active")) {
        $("#car-reservation-form #reservation_until_at").val("")
        if ($(this).data("date") == $("#car-reservation-form #reservation_since_at").val()) {
          $("#car-reservation-form #reservation_since_at").val("")
        }
        CarReservations.getCalendar()
      } else if (!$("#calendar table.calendar td.active").length) {
        var days = $("td:not(.disabled):not(.holidays)")
        $("#car-reservation-form #reservation_since_at").val($(this).data("date"))
        $("#car-reservation-form #reservation_until_at").val(
          $(days[days.index($(this)) + 1]).data("date")
        )
        CarReservations.checkReservationDateRange();
        return;
      } else if ($("#car-reservation-form #reservation_since_at").val() < $(this).data("date")) {
        $("#car-reservation-form #reservation_until_at").val($(this).data("date"))
        CarReservations.checkReservationDateRange();
        return;
      } else {
        $("#car-reservation-form #reservation_since_at").val($(this).data("date"))
      }
      CarReservations.setClassInCalendar()
    })
    $("#calendar .calendar-header").find('.prev,.next').click(function() {
      $("#calendar").data("date", $(this).data("date"))
      $("#car-reservation-form .calendar-date").val($(this).data("date"))
      $("#car-reservation-form #reservation_until_at").val("")
      $("#car-reservation-form #reservation_since_at").val("")
      $("#car-reservation-form input.date").val("")
      CarReservations.getCalendar()
    })
  },

  checkReservationDateRange: function() {
    var since_at = new Date($("#car-reservation-form #reservation_since_at").val())
    var until_at = new Date($("#car-reservation-form #reservation_until_at").val())
    for ( ; since_at <= until_at; since_at.setDate(since_at.getDate() + 1) ) {
      var td = $("#calendar table.calendar td[data-date='"+ CarReservations.formatDate(since_at)+"']")
      if (td.hasClass("blockade")) {
        $("#car-reservation-form #reservation_until_at").val("")
        return
      }
    }
    CarReservations.getCalendar()
  },

  setClassInCalendar: function() {
    var since_at = $("#car-reservation-form #reservation_since_at").val()
    var until_at = $("#car-reservation-form #reservation_until_at").val()
    var arr = []
    $("#calendar table.calendar td").removeClass("active").removeClass("active-range")
    $("#calendar table.calendar td[data-date='"+ since_at +"']").addClass("active")
    $("#calendar table.calendar td[data-date='"+ until_at +"']").addClass("active")
    if (since_at.length) {
      arr.push(since_at.split("-").reverse().join("."))
      since_at = new Date(since_at) 
    }
    if (until_at.length) {
      arr.push(until_at.split("-").reverse().join("."))
      until_at = new Date(until_at)
    }
    $("#car-reservation-form input.date").val(arr.join(" - "))
    if (since_at && until_at) {
      since_at.setDate(since_at.getDate() + 1)
      for ( ; since_at < until_at; since_at.setDate(since_at.getDate() + 1) ) {
        var td = $("#calendar table.calendar td[data-date='"+ CarReservations.formatDate(since_at)+"']")
        td.addClass("active-range")
      }
    }
  },

  formatDate: function(date) {
    return [
        date.getFullYear(),
        ('0' + (date.getMonth() + 1)).slice(-2),
        ('0' + date.getDate()).slice(-2)
    ].join('-')
  },

  getCalendar: function() {
    var post_data = {
      car_id: $("#car-reservation-form #reservation_car_id").val(),
      since_at: $("#car-reservation-form #reservation_since_at").val(),
      until_at: $("#car-reservation-form #reservation_until_at").val(),
      date: $("#calendar").data("date"),
      authenticity_token: $('meta[name="csrf-token"]').attr('content'),
    }
    $.post(
      $("#calendar").data("url"),
      post_data,
      function(data, status, xhr) {
        CarReservations.bindClickDate()
        CarReservations.setClassInCalendar()
    })
  },
      
  bindPublications: function(parent_name) {
    $(parent_name).find(".add, .cancel, .collapse, .manage").click(function() {
      $(this).parents(".publications").toggleClass("collapsed")
    })


    $(parent_name + " form input").change(function() {
      Rails.fire($(this).parents("form")[0], 'submit')
    })

    $(parent_name + " form").submit(function(event) {
      if ($(event.target).attr("disabled")) {
        return false;
      } else {
        $(event.target).attr("disabled", "true")
      }
    })
  }


}
